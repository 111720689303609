/* eslint-disable @typescript-eslint/no-explicit-any */

import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { maxWidth, minWidth, Text, TextElements, FontSizes, FontWeights } from '@calm-web/design-system';

import GridWrapper from '@/components/ui/GridWrapper';

export const ColumnGrid = styled(GridWrapper)`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export const FooterColumn = styled.li`
	margin-bottom: 1rem;
`;

export const FooterList = styled.ul`
	padding: 0;
	list-style: none;
`;

export const FooterHeader = styled(Text).attrs({
	el: TextElements.H2,
	color: 'white',
	size: FontSizes.base,
	weight: FontWeights.Medium,
})``;

export const FooterItem = styled.a`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	opacity: 0.8;
	transition: ${theme('animation.transition')};
	text-decoration: none;

	&:hover {
		opacity: 0.5;
	}
`;

export const Wrapper = styled.footer<{
	$theme?: 'light' | 'dark' | 'transparent';
}>`
	background-color: ${p =>
		// eslint-disable-next-line no-nested-ternary
		p.$theme === 'light' ? palette('white') : p.$theme === 'dark' ? palette('footerBlue') : 'transparent'};
	padding: 48px 20px;

	${minWidth('desktop')} {
		padding: 48px 0;
	}

	${FooterHeader} {
		color: ${p => (p.$theme === 'dark' || p.$theme === 'transparent' ? palette('white') : palette('gray6'))};
	}

	.Collapsible__trigger svg {
		path {
			fill: ${p => (p.$theme === 'dark' || p.$theme === 'transparent' ? palette('white') : palette('gray6'))};
		}
	}

	${FooterItem} {
		color: ${p => (p.$theme === 'dark' || p.$theme === 'transparent' ? palette('white') : palette('gray6'))};
	}
`;

export const SocialIconsColumn = styled.ul<{
	$theme?: 'light' | 'dark' | 'transparent';
}>`
	display: flex;
	justify-content: center;
	padding: 0;

	& svg {
		fill: ${p => (p.$theme === 'dark' || p.$theme === 'transparent' ? palette('white') : palette('gray6'))};
		height: 18px;
		width: 18px;
	}

	li {
		display: block;
		list-style: none;
		margin-right: 18px;

		&:last-of-type {
			margin-right: 0;
		}
	}

	${minWidth('tablet')} {
		justify-content: flex-end;
	}
`;

export const SocialIcon = styled.a`
	opacity: 0.8;
`;

export const Copyright = styled.div<{
	$theme?: 'light' | 'dark' | 'transparent';
}>`
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	margin-top: 48px;
	opacity: 0.5;
	color: ${p => (p.$theme === 'dark' || p.$theme === 'transparent' ? palette('white') : palette('gray6'))};

	${maxWidth('tablet')} {
		margin: 22px auto;
		align-content: center;
	}
`;

export const TiCLink = styled(Copyright)`
	margin-top: 15px;
`;
