import styled from 'styled-components';

import { Button, minWidth } from '@calm-web/design-system';

export const Wrapper = styled(Button)`
	text-align: center;
	max-width: 180px;

	${minWidth('desktop')} {
		max-width: 250px;
	}
`;
